<template>
  <div v-if="show == true" class="form__group">
    <div class="form__field">
      <InputField :data="data" :id="id" />
    </div>
    <span class="form__message"></span>
  </div>
</template>

<script>
import InputField from "@/components/Forms/InputField.vue";

export default {
  name: "FormConditionalGroup",
  props: {
    data: { type: Object },
    id: { type: String },
  },
  components: {
    InputField,
  },
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    this.$root.$on("selectOption", (e) => {
      if (e.name && e.name === this.data.name) this.show = e.show;
    });
  },
};
</script>

<style lang="scss" scoped>
@import "/Form";
</style>
