<template>
  <label :for="id + '-' + data.name" class="form__label">{{ data }}</label>
</template>

<script>
export default {
  name: "LabelField",
  props: {
    data: { type: String },
    id: { type: String },
  },
};
</script>

<style lang="scss" scoped>
@import "/Form";
</style>
