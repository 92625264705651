<template>
  <div v-if="data.type != 'hidden'" class="form__group">
    <LabelField
      :data="data.label"
      :id="id"
      v-if="data.type == 'radio' || data.type == 'checkbox'"
    />
    <div class="form__field">
      <TitleField v-if="data.type == 'title'" :data="data" :id="id" />
      <div class="select" v-else-if="data.type == 'select'">
        <SelectField :data="data" :id="id" @changeOption="changeOption" />
      </div>
      <div class="select" v-else-if="data.type == 'time'">
        <TimePicker :data="data" :id="id" @changeOption="changeOption" />
      </div>

      <RadioField
        v-else-if="data.type == 'radio'"
        :data="data"
        :id="id"
        @selectOption="selectOption"
      />
      <CheckboxField
        v-else-if="data.type == 'checkbox'"
        :data="data"
        :id="id"
      />

      <div v-else-if="data.type == 'date'">
        <InputField class="inputText" :data="data" :id="id" />
        <LabelField
          class="floating-label focused"
          :data="data.label"
          :id="id"
        />
      </div>
      <InputField
        v-else
        :data="data"
        :id="id"
        @input-error="handleInputError"
      />
      <!-- <LabelField class="floating-label" :data="data.label" :id="id" /> -->
    </div>
    <span
      v-if="(data.name === 'phone' || data.name === 'email') && errorMessage"
      class="form__message"
    >
      {{ errorMessage }}</span
    >
    <span v-else-if="data.type != 'title'" class="form__message"></span>
  </div>
  <InputField v-else :data="data" :id="id" @input-error="handleInputError" />
</template>

<script>
import CheckboxField from "@/components/Forms/CheckboxField.vue";
import InputField from "@/components/Forms/InputField.vue";
import LabelField from "@/components/Forms/LabelField.vue";
import RadioField from "@/components/Forms/RadioField.vue";
import SelectField from "@/components/Forms/SelectField.vue";
import TimePicker from "@/components/Forms/TimePicker.vue";
import TitleField from "@/components/Forms/TitleField.vue";

export default {
  name: "FormGroup",
  data() {
    return {
      errorMessage: "",
    };
  },
  props: {
    data: { type: Object },
    id: { type: String },
  },
  components: {
    CheckboxField,
    InputField,
    LabelField,
    RadioField,
    SelectField,
    TimePicker,
    TitleField,
  },
  methods: {
    selectOption(e) {
      this.$root.$emit("selectOption", e.conditional);
    },
    changeOption(e) {
      this.$emit("changeOption", e);
    },
    handleInputError(errorMessage) {
      //console.log("Error from input field:", errorMessage);
      this.errorMessage = errorMessage;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Form";
</style>
