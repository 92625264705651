<template>
  <select
    :name="data.name"
    :id="id + '-' + data.name"
    :data-label="data.label"
    :data-errormessage="data.errormessage"
    :disabled="data.disabled ? true : false"
    class="form__control required"
    @change="changeOption"
  >
    <option value="">{{ data.label }}</option>
    <option
      v-for="(option, index) in data.options"
      :key="index"
      :value="option.value"
    >
      {{ option.label }}
    </option>
  </select>
</template>

<script>
export default {
  name: "SelectField",
  props: {
    data: { type: Object },
    id: { type: String },
  },
  methods: {
    changeOption(e) {
      this.$emit("changeOption", e.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Form";
</style>
