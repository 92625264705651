export const messageErrors = {
  pt: {
    defaultMessage: "Campo obrigatório",
    emailMessage: "Coloque um email válido",
    phoneMessage: "Coloque um número de telefone válido",
    plateMessage: "Coloque uma matrícula válida",
    vinMessage: "Coloque um VIN válido",
    nifMessage: "Coloque um NIF válido",
    postalCodeMessage: "Coloque um código-postal válido",
    selectionMessage: "Tem que selecionar uma das opções",
    timeMessage: "Coloque uma hora válida",
    dateMessage: "Coloque uma data válida",
    checkboxMessage: "Tem que selecionar pela menos uma opção",
    captchaMessage: "reCaptcha obrigatório",
    lengthMessage: "O campo tem que ter {#length} caracteres",
    maxValueMessage: "O campo tem que ter no máximo {#maxLength} caracteres",
    minValueMessage: "O campo tem que ter no mínimo {#minLength} caracteres",
    minMaxValueMessage:
      "O campo tem que ter entre {#minLength} e {#maxLength} caracteres",
    radioMessage: "Tem que selecionar uma opção",
    selectedMessage: "Tem que selecionar {#length} opção/opções",
    maxSelectedMessage: "Só pode selecionar até {#maxLength} opção/opções",
    minSelectedMessage:
      "Tem que selecionar pelo menos {#minLength} opção/opções",
    minMaxSelectedMessage:
      "Tem que selecionar entre {#minLength} e {#maxLength} opção/opções",
    minTimeMessage: "Tem que selecionar uma hora a partir das {#minTime}",
    maxTimeMessage: "Tem que selecionar uma hora até às {#maxTime}",
    minMaxTimeMessage:
      "Tem que selecionar uma hora entre as {#minTime} e as {#maxTime}",
    minDateMessage: "Tem que selecionar uma data a partir de {#minDate}",
    maxDateMessage: "Tem que selecionar uma data até {#maxDate}",
    minMaxDateMessage:
      "Tem que selecionar uma data entre {#minDate} e {#maxDate}",
    fileTypeMessage: "O ficheiro tem de ser do tipo {#fileType}",
  },
  en: {
    defaultMessage: "Required field",
    emailMessage: "Enter a valid email address",
    phoneMessage: "Enter a valid phone number",
    plateMessage: "Enter a valid number plate",
    vinMessage: "Enter a valid VIN",
    nifMessage: "Enter a valid VAT number",
    postalCodeMessage: "Enter a valid postcode",
    selectionMessage: "You must select one of the options",
    timeMessage: "Enter a valid time",
    dateMessage: "Enter a valid date",
    checkboxMessage: "You must select at least one option",
    captchaMessage: "reCaptcha required",
    lengthMessage: "Field must have {#length} characters",
    maxValueMessage: "Field must have at most {#maxLength} characters",
    minValueMessage: "Field must have at least {#minLength} characters",
    minMaxValueMessage:
      "Field has to have between {#minLength} and {#maxLength} characters",
    radioMessage: "You must select an option",
    selectedMessage: "You must select {#length} options",
    maxSelectedMessage: "You must only select up to {#maxLength} option(s)",
    minSelectedMessage: "You must select at least {#minLength} option(s)",
    minMaxSelectedMessage:
      "You must select between {#minLength} and {#maxLength} option(s)",
    minTimeMessage: "You must select a time from {#minTime}",
    maxTimeMessage: "You must select a time up to {#maxTime}",
    minMaxTimeMessage:
      "You must select a time between {#minTime} and {#maxTime}",
    minDateMessage: "You must select a date from {#minDate}",
    maxDateMessage: "You must select a date until {#maxDate}",
    minMaxDateMessage:
      "You must select a date between {#minDate} and {#maxDate}",
    fileTypeMessage: "The file must be of type {#fileType}",
  },
  es: {
    defaultMessage: "Campo requerido",
    emailMessage: "Introduzca una dirección de correo electrónico válida",
    phoneMessage: "Introduce un número de teléfono válido",
    plateMessage: "Introduce una matrícula válida",
    vinMessage: "Introduce un VIN válido",
    nifMessage: "Introduce un número de IVA válido",
    postalCodeMessage: "Introduce un código postal válido",
    selectionMessage: "Debe seleccionar una de las opciones",
    timeMessage: "Introduce una hora válida",
    dateMessage: "Introduzca una fecha válida",
    checkboxMessage: "Debe seleccionar al menos una opción",
    captchaMessage: "reCaptcha requerido",
    lengthMessage: "El campo debe tener {#length} caracteres",
    maxValueMessage: "El campo debe tener como máximo {#maxLength} caracteres",
    minValueMessage: "El campo debe tener al menos {#minLength} caracteres",
    minMaxValueMessage:
      "El campo debe tener entre {#minLength} y {#maxLength} caracteres",
    radioMessage: "Debe seleccionar una opción",
    selectedMessage: "Debes seleccionar {#length} opción(es)",
    maxSelectedMessage: "Sólo debe seleccionar hasta {#maxLength} opción(es)",
    minSelectedMessage: "Debe seleccionar al menos {#minLength} opción(es)",
    minMaxSelectedMessage:
      "Debe seleccionar entre {#minLength} y {#maxLength} opción(es)",
    minTimeMessage: "Debe seleccionar un horario de {#minTime}",
    maxTimeMessage: "Debe seleccionar un tiempo hasta {#maxTime}",
    minMaxTimeMessage:
      "Debe seleccionar un tiempo entre {#minTime} y {#maxTime}",
    minDateMessage: "Debe seleccionar una fecha de {#minDate}",
    maxDateMessage: "Debe seleccionar una fecha antes de {#maxDate}",
    minMaxDateMessage: "Debe seleccionar una fecha de {#minDate} y {#maxDate}",
    fileTypeMessage: "El archivo debe ser del tipo {#fileType}",
  },
  fr: {
    defaultMessage: "Champs requis",
    emailMessage: "Entrez une adresse e-mail valide",
    phoneMessage: "Entrez un numéro de téléphone valide",
    plateMessage: "Entrez une plaque d'immatriculation valide",
    vinMessage: "Entrez un NIV valide",
    nifMessage: "Entrez un numéro de TVA valide",
    postalCodeMessage: "Entrez un code postal valide",
    selectionMessage: "Vous devez sélectionner l'une des options",
    timeMessage: "Entrez une heure valide",
    dateMessage: "Entrez une date valide",
    checkboxMessage: "Vous devez sélectionner au moins une option",
    captchaMessage: "reCaptcha requis",
    lengthMessage: "Le champ doit contenir {#length} caractères",
    maxValueMessage: "Le champ doit avoir au plus {#maxLength}  caractères",
    minValueMessage: "Le champ doit avoir au moins {#minLength}  caractères",
    minMaxValueMessage:
      "Le champ doit avoir entre {#minLength} et {#maxLength}  caractères",
    radioMessage: "Vous devez sélectionner une option",
    selectedMessage: "Vous devez sélectionner {#length} option(s)",
    maxSelectedMessage:
      "Vous ne devez sélectionner que jusqu'à {#maxLength} option(s)",
    minSelectedMessage:
      "Vous devez sélectionner au moins {#minLength} option(s)",
    minMaxSelectedMessage:
      "Vous devez choisir entre {#minLength} et {#maxLength} option(s)",
    minTimeMessage: "Vous devez sélectionner une heure à partir de {#minTime}",
    maxTimeMessage: "Vous devez sélectionner une heure jusqu'à {#maxTime}",
    minMaxTimeMessage:
      "Vous devez sélectionner une heure entre {#minTime} et {#maxTime}",
    minDateMessage: "Vous devez sélectionner une date à partir de {#minDate}",
    maxDateMessage: "Vous devez sélectionner une date avant {#maxDate}",
    minMaxDateMessage:
      "Vous devez sélectionner une date entre {#minDate} et {#maxDate}",
    fileTypeMessage: "Le fichier doit être de type {#fileType}",
  },
};
