import { messageErrors } from "@/functions/forms-error-messages.js";
import axios from "axios";

/*
@const array - errorObj - Array onde vão ser inseridos todos os campos onde se encontrarem erros
@let string - lang - Determina a linguagem do formulário para as messagens de erro !!A VERIFICAR!!
@const element - forms - Retorna todos os formulários da página !!A VERIFICAR!!
*/

const errorObj = [];
let lang = "pt";

export function submitForm(id, isService = false, isOS = false) {
  /*
@const element - form - Retorna o formulário que despolotou a função
@const element - requiredFields - Retorna os elementos de preenchimento obrigatório dentor do formulário referido anteriormrente
@const element - submitBtn - Retorna o botão de submissão do formulário referido anteriormrente
@const object - objForm - Objeto onde vão ser inseridos todos os campos de preenchimento obrigatório encontrados
*/
  const form = document.querySelector("#" + id);
  const requiredFields = form.querySelectorAll(
    ".required:not([type='hidden'])"
  );

  const objForm = { fields: [] };

  requiredFields.forEach((el) => {
    const id = el.id.trim();

    switch (el.dataset.type) {
      case "email":
        objForm["fields"].push({
          id,
          type: "email",
        });
        break;
      case "phone":
        objForm["fields"].push({
          id,
          minLength: el.minLength,
          maxLength: el.maxLength,
          type: "phone",
        });
        break;
      case "plate":
        objForm["fields"].push({
          id,
          country: el.dataset.country,
          type: "plate",
        });
        break;
      case "vin":
        objForm["fields"].push({
          id,
          minLength: 17,
          maxLength: 17,
          type: "vin",
        });
        break;
      case "nif":
        objForm["fields"].push({
          id,
          country: el.dataset.country,
          type: "nif",
        });
        break;
      case "postalCode":
        objForm["fields"].push({
          id,
          country: el.dataset.country,
          type: "postalCode",
        });
        break;
      case "date":
        objForm["fields"].push({
          id,
          minDate: el.dataset.mindate ? el.dataset.mindate : null,
          maxDate: el.dataset.maxdate ? el.dataset.maxdate : null,
          type: "date",
        });
        break;
      case "time":
        objForm["fields"].push({
          id,
          minTime: el.dataset.mintime,
          maxTime: el.dataset.maxtime,
          type: "time",
        });
        break;
      case "file":
        objForm["fields"].push({
          id,
          fileType: el.dataset.filetype,
          type: "file",
        });
        break;
      case "rcField":
        objForm["fields"].push({
          id,
          type: "captcha",
        });
        break;
      case "checkbox":
        objForm["fields"].push({
          id,
          minLength: el.dataset.minselected ? el.dataset.minselected : 1,
          maxLength: el.dataset.maxselected,
          errorMessage: el.dataset.errormessage,
          hasBypassMessage: el.dataset.errormessage ? true : false,
          type: "checkbox",
        });
        break;
      case "radio":
        objForm["fields"].push({
          id,
          errorMessage: el.dataset.errormessage,
          hasBypassMessage: el.dataset.errormessage ? true : false,
          type: "radio",
        });
        break;
      default:
        objForm["fields"].push({
          id,
          minLength: el.minLength,
          maxLength: el.maxLength,
          errorMessage: el.dataset.errormessage,
          hasBypassMessage: el.dataset.errormessage ? true : false,
        });
    }
  });
  if (rgValidateForm(objForm) && isOS) {
    return true;
  } else if (isOS && !rgValidateForm(objForm)) {
    return false;
  }

  if (rgValidateForm(objForm)) {
    sendForm(id, form, isService);
  }
}

function sendForm(id, form, isService) {
  const submitBtn = form.querySelector(".submitBtn-" + id);

  submitBtn.classList.add("disabled-btn");

  if (isService) {
    form.submit();
  } else {
    axios.post(form.action, new FormData(form)).then((response) => {
      if (response.data.status == "success") {
        window.location.href = window.location.origin + "/confirmacao";
      } else {
        window.location.href = window.location.origin + "/erro";
      }
    });
  }
}

export function rgValidateForm(objForm) {
  // console.log("validate", objForm);
  __clearErrors(objForm.fields);

  objForm.fields.forEach((el) => {
    if (
      !__isNotEmpty(el, el.errorMessage ? el.errorMessage : "defaultMessage")
    ) {
      errorObj.push(el);
    } else {
      switch (el.type) {
        case "email":
          if (!__isEmail(el, "emailMessage")) errorObj.push(el);
          break;
        case "phone":
          if (!__isNumber(el, "phoneMessage")) errorObj.push(el);
          break;
        case "plate":
          if (!__checkCountry(el, "plateMessage", "plate")) errorObj.push(el);
          break;
        case "vin":
          if (!__isVin(el, "vinMessage")) errorObj.push(el);
          break;
        case "nif":
          if (!__checkCountry(el, "nifMessage", "nif")) errorObj.push(el);
          break;
        case "postalCode":
          if (!__checkCountry(el, "postalCodeMessage", "postalCode"))
            errorObj.push(el);
          break;
        case "date":
          if (!__isDate(el, "dateMessage")) errorObj.push(el);
          break;
        case "time":
          if (!__isTime(el, "timeMessage")) errorObj.push(el);
          break;
        case "file":
          if (!__isFile(el, "fileTypeMessage")) errorObj.push(el);
          break;
        case "checkbox":
          if (
            !__isCheckbox(
              el,
              el.errorMessage ? el.errorMessage : "defaultMessage"
            )
          )
            errorObj.push(el);
          break;
        case "radio":
          if (
            !__isRadio(el, el.errorMessage ? el.errorMessage : "radioMessage")
          )
            errorObj.push(el);
          break;
        default:
          "";
      }
    }
  });

  if (errorObj.length == 0) {
    return true;
  } else {
    __showErrors(errorObj);
    return false;
  }
}

/* UTILITY FUNCTIONS */

function __showErrors(errorObj) {
  /*
@const formGroup - Retorna o elemento pai do input especificado
@const formMessage - Retorna o elemento de mesnagem de erro dentro do elemento pai encontrado
*/
  errorObj.forEach((el) => {
    const formGroup = document.querySelector("#" + el.id).parentElement
      .parentElement.parentElement;
    //ADICIONA CLASSE DE ERRO
    formGroup.classList.add("form--error");

    //ADICIONA MENSAGEM DE ERRO
    const formMessage = formGroup.querySelector(".form__message");
    //REMOVE MENSAGEM DE ERRO
    if (formMessage) formMessage.innerHTML = el.messageError;
  });
}

function __clearErrors(objForm) {
  /*
@const formGroup - Retorna o elemento pai do input especificado
@const formMessage - Retorna o elemento de mesnagem de erro dentro do elemento pai encontrado
*/

  //LIMPA O OBJETO DE ERROS
  errorObj.splice(0, errorObj.length);

  objForm.forEach((el) => {
    const formGroup = document.querySelector("#" + el.id).parentElement
      .parentElement;
    //REMOVE CLASSE DE ERRO
    if (formGroup) formGroup.classList.remove("form--error");

    const formMessage = formGroup.querySelector(".form__message");
    //REMOVE MENSAGEM DE ERRO
    if (formMessage) formMessage.innerHTML = "";
  });
}

function __getFieldValue(id) {
  /*
@const element - field - Retorna elemento com o id especificado
@let string - value - Retorna valor do elemento
@const string - type - Retorna tipo do elemento
*/
  const field = document.querySelector("#" + id);
  let value = "";

  value = field ? field.value : "";

  return value;
}

function __setMessageError(obj, message, replace = null) {
  const messagesLang = messageErrors[lang] ? lang : "pt";
  if (obj.hasBypassMessage) {
    obj.messageError = message;
  } else {
    switch (message) {
      case "minMaxValueMessage":
      case "minMaxSelectedMessage":
        obj.messageError = messageErrors[messagesLang][message]
          .replace("{#minLength}", replace.minLength)
          .replace("{#maxLength}", replace.maxLength);
        break;
      case "minValueMessage":
      case "minSelectedMessage":
        obj.messageError = messageErrors[messagesLang][message].replace(
          "{#minLength}",
          replace.minLength
        );
        break;
      case "maxValueMessage":
      case "maxSelectedMessage":
        obj.messageError = messageErrors[messagesLang][message].replace(
          "{#maxLength}",
          replace.maxLength
        );
        break;
      case "lengthMessage":
      case "selectedMessage":
        obj.messageError = messageErrors[messagesLang][message].replace(
          "{#length}",
          replace.length
        );
        break;
      case "minTimeMessage":
        obj.messageError = messageErrors[messagesLang][message].replace(
          "{#minTime}",
          replace.minTime
        );
        break;
      case "maxTimeMessage":
        obj.messageError = messageErrors[messagesLang][message].replace(
          "{#maxTime}",
          replace.maxTime
        );
        break;
      case "minMaxTimeMessage":
        obj.messageError = messageErrors[messagesLang][message]
          .replace("{#minTime}", replace.minTime)
          .replace("{#maxTime}", replace.maxTime);
        break;
      case "minDateMessage":
        obj.messageError = messageErrors[messagesLang][message].replace(
          "{#minDate}",
          replace.minDate
        );
        break;
      case "maxDateMessage":
        obj.messageError = messageErrors[messagesLang][message].replace(
          "{#maxDate}",
          replace.maxDate
        );
        break;
      case "minMaxDateMessage":
        obj.messageError = messageErrors[messagesLang][message]
          .replace("{#minDate}", replace.minDate)
          .replace("{#maxDate}", replace.maxDate);
        break;
      case "fileTypeMessage":
        obj.messageError = messageErrors[messagesLang][message].replace(
          "{#fileType}",
          replace.fileType
        );
        break;
      default:
        obj.messageError = messageErrors[messagesLang][message];
    }
  }
}

function __checkLength(obj, numberSelected = null) {
  if (obj.minLength > 0 && obj.maxLength > 0) {
    if (obj.minLength != obj.maxLength) {
      if (__minMaxLength(obj, obj.minLength, obj.maxLength, numberSelected)) {
        return true;
      }
    } else if (obj.minLength == obj.maxLength) {
      if (__length(obj, obj.maxLength, numberSelected)) {
        return true;
      }
    }
  } else if (obj.maxLength > 0) {
    if (__maxLength(obj, obj.maxLength, numberSelected)) {
      return true;
    }
  } else if (obj.minLength > 0) {
    if (__minLength(obj, obj.minLength, numberSelected)) {
      return true;
    }
  } else {
    return true;
  }

  return false;
}

function __minMaxLength(obj, minLength, maxLength, numberSelected = null) {
  /*
@const string messageError - Retorna o tipo de mensagem conforme se tiver numberSelected (se for checkbox) ou não
*/

  const messageError =
    numberSelected != null ? "minMaxSelectedMessage" : "minMaxValueMessage";

  if (__minLength(obj, minLength, numberSelected)) {
    if (__maxLength(obj, maxLength, numberSelected)) {
      return true;
    }
  }

  __setMessageError(obj, messageError, {
    minLength: minLength,
    maxLength: maxLength,
  });

  return false;
}

function __maxLength(obj, length, numberSelected = null) {
  /*
@const value - Retorna valor doelemento com o id especificado
@const int valueLength - Retorna o tamanho do valor do elemento ou número de checboxes selecionadas (numberSelected)
@const string messageError - Retorna o tipo de mensagem conforme se tiver numberSelected (se for checkbox) ou não
*/
  const value = __getFieldValue(obj.id);
  const valueLength = numberSelected != null ? numberSelected : value.length;
  const messageError =
    numberSelected != null ? "maxSelectedMessage" : "maxValueMessage";

  __setMessageError(obj, messageError, { maxLength: length });

  return valueLength <= length;
}

function __minLength(obj, length, numberSelected = null) {
  /*
@const string - value - Retorna valor doelemento com o id especificado
@const int valueLength - Retorna o tamanho do valor do elemento ou número de checboxes selecionadas (numberSelected)
@const string messageError - Retorna o tipo de mensagem conforme se tiver numberSelected (se for checkbox) ou não
*/
  const value = __getFieldValue(obj.id);
  const valueLength = numberSelected != null ? numberSelected : value.length;
  const messageError =
    numberSelected != null ? "minSelectedMessage" : "minValueMessage";

  __setMessageError(obj, messageError, { minLength: length });

  return valueLength >= length;
}

function __length(obj, length, numberSelected = null) {
  /*
@const value - Retorna valor doelemento com o id especificado
@const int valueLength - Retorna o tamanho do valor do elemento ou número de checboxes selecionadas (numberSelected)
@const string messageError - Retorna o tipo de mensagem conforme se tiver numberSelected (se for checkbox) ou não
*/
  const value = __getFieldValue(obj.id);
  const valueLength = numberSelected != null ? numberSelected : value.length;
  const messageError =
    numberSelected != null ? "selectedMessage" : "lengthMessage";

  __setMessageError(obj, messageError, { length: length });

  return valueLength == length;
}

/* FIELD TYPES */

function __isNotEmpty(obj, errorMessage) {
  /*
@const string - value - Retorna valor doelemento com o id especificado
*/
  if (obj.type == "checkbox" || obj.type == "radio") return true;

  const value = __getFieldValue(obj.id);

  __setMessageError(obj, errorMessage);

  if (value != "") {
    if (__checkLength(obj)) return true;
  }

  if (obj.hasBypassMessage) __setMessageError(obj, errorMessage);

  return false;
}

function __isEmail(obj, errorMessage) {
  /*
@const string - EMAIL_REGEX - Regular Expression para email
@const string - value - Retorna valor doelemento com o id especificado
*/
  const EMAIL_REGEX =
    '/^(([^<>()[]\\.,;:s@"]+(.[^<>()[]\\.,;:s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/;';
  const value = __getFieldValue(obj.id);

  __setMessageError(obj, errorMessage);

  return new RegExp(EMAIL_REGEX).test(value);
}

function __isNumber(obj, errorMessage) {
  /*
@const regex - NUMBER_REGEX - Regular Expression para email
@const value - Retorna valor doelemento com o id especificado
*/
  const NUMBER_REGEX = /^\d+$/;
  const value = __getFieldValue(obj.id);

  __setMessageError(obj, errorMessage);

  if (new RegExp(NUMBER_REGEX).test(value)) {
    if (__checkLength(obj)) return true;
  }

  if (obj.hasBypassMessage) __setMessageError(obj, errorMessage);

  return false;
}

function __isVin(obj, errorMessage) {
  __setMessageError(obj, errorMessage);

  return __checkLength(obj);
}

function __checkCountry(obj, errorMessage, type) {
  /*
@const array - countries - Array com os países para validar
*/
  const countries = obj.country.split(",");

  __setMessageError(obj, errorMessage);

  for (let i = 0; i < countries.length; i++) {
    countries[i] = countries[i].trim();
    switch (countries[i]) {
      case "pt":
        switch (type) {
          case "plate":
            if (__isPTPlate(obj)) {
              return true;
            }
            break;
          case "nif":
            if (__isPTNif(obj)) {
              return true;
            }
            break;
          case "postalCode":
            if (__isPTPostalCode(obj)) {
              return true;
            }
            break;
          default:
            return true;
        }
        break;
      default:
        return true;
    }
  }

  return false;
}

function __isPTPlate(obj) {
  /*
@const PLATE_REGEX_1 - Regular Expression para email
@const PLATE_REGEX_2 - Regular Expression para email
@const PLATE_REGEX_3 - Regular Expression para email
@const value - Retorna valor doelemento com o id especificado

MATRÍCULAS VÁLIDAS:
AA-00-AA | AA-00-00 | 00-AA-00 | 00-00-AA
AA 00 AA | AA 00 00 | 00 AA 00 | 00 00 AA
AA00AA | AA0000 | 00AA00 | 0000AA
*/
  const PLATE_REGEX_1 =
    "^(([A-Z]{2}-[0-9]{2}-([0-9]{2}|[A-Z]{2}))|([0-9]{2}-([0-9]{2}-[A-Z]{2}|[A-Z]{2}-[0-9]{2})))$";
  const PLATE_REGEX_2 =
    "^(([A-Z]{2}[0-9]{2}([0-9]{2}|[A-Z]{2}))|([0-9]{2}([0-9]{2}[A-Z]{2}|[A-Z]{2}[0-9]{2})))$";
  const PLATE_REGEX_3 =
    "^(([A-Z]{2} [0-9]{2} ([0-9]{2}|[A-Z]{2}))|([0-9]{2} ([0-9]{2} [A-Z]{2}|[A-Z]{2} [0-9]{2})))$";

  const value = __getFieldValue(obj.id);

  return (
    new RegExp(PLATE_REGEX_1).test(value.toUpperCase()) ||
    new RegExp(PLATE_REGEX_2).test(value.toUpperCase()) ||
    new RegExp(PLATE_REGEX_3).test(value.toUpperCase())
  );
}

function __isPTNif(obj) {
  /*
@const string value - Retorna valor doelemento com o id especificado
*/
  const value = __getFieldValue(obj.id);

  if (
    !["1", "2", "3", "5", "6", "8"].includes(value.substring(0, 1)) ||
    !["45", "70", "71", "72", "77", "79", "90", "91", "98", "99"].includes(
      value.substring(0, 2)
    )
  ) {
    const total =
      value[0] * 9 +
      value[1] * 8 +
      value[2] * 7 +
      value[3] * 6 +
      value[4] * 5 +
      value[5] * 4 +
      value[6] * 3 +
      value[7] * 2;

    const modulo11 = total - parseInt(total / 11) * 11;
    const comparador = modulo11 == 1 || modulo11 == 0 ? 0 : 11 - modulo11;

    if (value[8] == comparador) return true;
  }

  return false;
}

function __isPTPostalCode(obj) {
  /*
@const string - POSTALCODEPT_REGEX - Regular Expression para email
@const string - value - Retorna valor doelemento com o id especificado
*/
  const POSTALCODEPT_REGEX = "^\\d{4}-\\d{3}$";
  const value = __getFieldValue(obj.id);

  return new RegExp(POSTALCODEPT_REGEX).test(value);
}

function __isFile(obj, errorMessage) {
  /*
@let boolean - valid - Guarda condição se o campo é válido ou não
@let string - fileName - Retorna o caminho do ficheiro
@const string - value - Retorna valor doelemento com o id especificado
*/
  let valid = false;
  const value = __getFieldValue(obj.id);
  const fileName = value.split(".").at(-1);

  obj.fileType.split(",").forEach((el) => {
    el = el.replaceAll(" ", "");
    el = el.replaceAll(".", "");

    if (!valid) valid = fileName == el;
  });

  __setMessageError(obj, errorMessage, { fileType: obj.fileType });

  return valid;
}

function __isTime(obj, errorMessage) {
  /*
@const string - TIME_REGEX - Regular Expression para email
@const string -  value - Retorna valor doelemento com o id especificado
@const int - timeStart = Retorna a hora mínima possível, se existir senão devolve a hora selecionada, em UNIX timestamp
@const int - timeEnd = Retorna a hora máxima possível, se existir senão devolve a hora selecionada, em UNIX timestamp
@const int - timeSelected = Retorna a hora selecionada em UNIX timestamp
@const string - secondErrorMessage = Retorna o tipo de mensagem se tier valor mínimo ou máximo
*/
  const TIME_REGEX = "^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$";
  const value = __getFieldValue(obj.id);
  const timeStart = obj.minTime
    ? new Date("01/01/1970 " + obj.minTime).getTime()
    : new Date("01/01/1970 " + value).getTime();
  const timeEnd = obj.maxTime
    ? new Date("01/01/1970 " + obj.maxTime).getTime()
    : new Date("01/01/1970 " + value).getTime();
  const timeSelected = new Date("01/01/1970 " + value).getTime();
  const secondErrorMessage =
    obj.minTime && obj.maxTime
      ? "minMaxTimeMessage"
      : obj.minTime
      ? "minTimeMessage"
      : obj.maxTime
      ? "maxTimeMessage"
      : "";

  __setMessageError(obj, errorMessage);

  if (new RegExp(TIME_REGEX).test(value)) {
    if (timeSelected >= timeStart && timeSelected <= timeEnd) {
      return true;
    } else {
      __setMessageError(obj, secondErrorMessage, {
        minTime: obj.minTime,
        maxTime: obj.maxTime,
      });
    }
  }

  return false;
}

function __isDate(obj, errorMessage) {
  /*
@const string -  value - Retorna valor doelemento com o id especificado
@const int - dateStart = Retorna a data mínima possível, se existir senão devolve a data selecionada, em UNIX timestamp
@const int - dateEnd = Retorna a data máxima possível, se existir senão devolve a data selecionada, em UNIX timestamp
@const int - dateSelected = Retorna a data  selecionada em UNIX timestamp
@const string - secondErrorMessage = Retorna o tipo de mensagem se tier valor mínimo ou máximo
*/

  const value = __getFieldValue(obj.id);
  const dateStart = obj.minDate
    ? new Date(obj.minDate).getTime()
    : new Date(value).getTime();
  const dateEnd = obj.maxDate
    ? new Date(obj.maxDate).getTime()
    : new Date(value).getTime();
  const dateSelected = new Date(value).getTime();
  const secondErrorMessage =
    obj.minDate && obj.maxDate
      ? "minMaxDateMessage"
      : obj.minDate
      ? "minDateMessage"
      : obj.maxDate
      ? "maxDateMessage"
      : "";
  __setMessageError(obj, errorMessage);

  if (!isNaN(new Date(value).getTime())) {
    if (dateSelected >= dateStart && dateSelected <= dateEnd) {
      return true;
    } else {
      __setMessageError(obj, secondErrorMessage, {
        minDate: obj.minDate,
        maxDate: obj.maxDate,
      });
    }
  }

  return false;
}

function __isCheckbox(obj, errorMessage) {
  /*
@const element -  checkboxGroup - Retorna o elemento com todas as checkboxes
@const element - checkboxes = Retorna todas as checkboxes dentro do elemento referenciado anteriormente
@let int - numberSelected = Contabiliza o número de checkboxes selecionadas para fazer a verificação do mínimo e máximo de checkboxes selecionadas possíveis
@let boolean - valid = Retorna bool da função checkLength


*/
  const checkboxGroup = document.querySelector("#" + obj.id);
  const checkboxes = checkboxGroup.querySelectorAll(".form__control");
  let numberSelected = 0;
  let valid = false;

  checkboxes.forEach((el) => {
    if (el.checked) numberSelected++;
  });

  __setMessageError(obj, errorMessage);

  valid = __checkLength(obj, numberSelected);

  if (obj.hasBypassMessage) __setMessageError(obj, errorMessage);

  return valid;
}

function __isRadio(obj, errorMessage) {
  /*
@const element -  radioGroup - Retorna o elemento com todas os radios
@const element - radios = Retorna todas os radios dentro do elemento referenciado anteriormente
@const boolean - isChecked = Se um dos radios estiver seleciondo retorna true
*/

  const radioGroup = document.querySelector("#" + obj.id);
  const radios = radioGroup.querySelectorAll(".form__control");
  let isChecked = false;

  __setMessageError(obj, errorMessage);

  radios.forEach((el) => {
    if (el.checked) isChecked = true;
  });

  if (obj.hasBypassMessage) __setMessageError(obj, errorMessage);

  return isChecked;
}
