<template>
  <div>
    <div
      data-type="radio"
      class="form__group--radio required"
      :data-label="data.label"
      :data-errormessage="data.errormessage"
      :id="id + '-' + data.name.trim()"
    >
      <template v-for="(option, index) in data.options">
        <input
          type="radio"
          :name="data.name"
          :id="data.name.trim() + '-' + option.value"
          :value="option.value"
          :key="'input-' + index"
          @change="selectOption"
          class="form__control"
        />
        <label :for="data.name + '-' + option.value" :key="'label-' + index">{{
          option.label
        }}</label>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: "RadioField",
  props: {
    data: { type: Object },
    id: { type: String },
  },
  methods: {
    selectOption(event) {
      if (this.data.conditional) {
        const value = event.target.value;
        this.data.conditional.show = value == "sim" ? true : false;
        this.$emit("selectOption", this.data);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Form";
</style>
