<template>
  <select
    :name="data.name"
    :id="id + '-' + data.name"
    :data-label="data.label"
    :data-errormessage="data.errormessage"
    :disabled="data.disabled ? true : false"
    class="form__control required"
    @change="changeOption"
  >
    <option value="">{{ data.label }}</option>
    <option v-for="(hour, index) in range" :key="index" :value="hour">
      {{ hour }}
    </option>
  </select>
</template>

<script>
export default {
  name: "SelectField",
  props: {
    data: { type: Object },
    id: { type: String },
  },
  data() {
    return {
      range: [],
    };
  },
  created() {
    const min = parseInt(this.data.validation.min.split(":")[0]);
    const maxTemp = parseInt(this.data.validation.max.split(":")[0]);
    const max = maxTemp > min ? maxTemp : maxTemp + 12;

    for (let i = min; i <= max; i++) {
      this.range.push(i + ":00");
    }
  },
  methods: {
    changeOption(e) {
      this.$emit("changeOption", e.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Form";
</style>
