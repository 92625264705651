<template>
  <p class="form__title">{{ data.label }}</p>
</template>

<script>
export default {
  name: "TitleField",
  props: {
    data: { type: Object },
    id: { type: String },
  },
};
</script>

<style lang="scss" scoped>
@import "/Form";
</style>
